import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { RiderProfileLayoutContextInterface } from "../RiderProfileLayout";
import ImageInput from "../../../Components/ImageInput";
import { patrollerService } from "../../../Api/Services/PatrollerService";
import { riderService } from "../../../Api/Services/RiderService";
import { useLoading } from "../../../Context/LoadingProvider";
import { RiderProductModel } from "../../../Api/Models/rider.model";
import { KitModel } from "../../../Api/Models/product.model";
import { ReturnStatus } from "../../../Api/Models/return.model";
import AssignNewGearItem from "../AssignNewGearItem";

const GearCheckStep: React.FunctionComponent<any> = ({setStep}) => {
    const { t } = useTranslation();
    const {
        setSubTitle,
        rider,
    } = useOutletContext<RiderProfileLayoutContextInterface>();
    const { startLoading, finnishLoading } = useLoading();
    const [showAssignNewGearItem, setShowAssignNewGearItem] = useState(false);
    const [gearsCondition, setGearsCondition] = useState<Record<any, any>>({});
    const [gearsOverdueCommunicated, setGearsOverdueCommunicated] = useState<boolean | undefined>(false);
    const [gearsNotTalabatCondition, setGearsNotTalabatCondition] = useState<Record<any, any>>({});
    const [gearsNotTalabat, setGearsNotTalabat] = useState<any[]>([]);
    const [gearsConfirmationImage, setGearsConfirmationImage] = useState<File[]>([]);
    const [gears, setGears] = useState<RiderProductModel[]>([]);
    const [missingKitProducts, setMissingKitProducts] = useState<KitModel | null>(null);
    const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);
    const historicalGear = localStorage.getItem("historicalGear");
    const gearReport = localStorage.getItem("gearReport");
    const [riderHasNoUniform, setRiderHasNoUniform] = useState(false);
    const [showError, setShowError] = useState(false);
    const riderApperanceIsGood = useMemo(() => {
        const shitToSend = [];
        for (const key in gearsCondition) {
            if (gearsCondition[key]["itemStatus"] === 'Good') {
                continue;
            }
            shitToSend.push(gearsCondition[key]);
        }

        for (const key in gearsNotTalabatCondition) {
            if (gearsNotTalabatCondition[key]["itemStatus"] === 'Good') {
                continue;
            }
            shitToSend.push(gearsNotTalabatCondition[key]);
        }

        return shitToSend.length <= 0;
    }, [gearsCondition, gearsNotTalabatCondition]);

    useEffect(() => {
        if (gearReport === '0') {
            setStep(3);
        }
    }, [gearReport, setStep]);

    useEffect(() => {
        setSubTitle();
    }, [t, setSubTitle]);

    const fetchGears = () => {
        startLoading("get-profile-gear");
        riderService
            .listGear(rider.riderId)
            .then((gear) => {
                const filteredGears = gear.filter((product) => {
                    return product.shouldBeChecked;
                })

                const uniqueGears = filteredGears.filter((product, index) => {
                    return filteredGears.findIndex(t => t.productId === product.productId && t.productVariationId === product.productVariationId) === index;
                });

                setGears(uniqueGears);
            })
            .finally(() => {
                finnishLoading("get-profile-gear");
            });

        fetchNonTalabatGears();
    };

    const fetchNonTalabatGears = () => {
        startLoading("get-non-talabat-gear");
        patrollerService
            .getNonTalabatGears()
            .then((gears) => {
                setGearsNotTalabat(gears);
            })
            .finally(() => {
                finnishLoading("get-non-talabat-gear");
            });

        fetchMissingKitProducts();
    };

    const fetchMissingKitProducts = () => {
        startLoading("get-missing-produts");
        riderService
            .getMissingKitProducts(rider.riderId)
            .then((kit) => {
                setMissingKitProducts(kit);
            })
            .finally(() => {
                finnishLoading("get-missing-produts");
            });
    };

    useEffect(() => {
        for (let i = 0; i < gears.length; i++) {
            gearsCondition[i.toString()] = {};

            gearsCondition[i.toString()]["itemStatus"] = undefined;
            gearsCondition[i.toString()]["imageUrl"] = gears[i].productImage;
            gearsCondition[i.toString()]["gearName"] = gears[i].productName + " " + (gears[i].variationName ?? "");
            gearsCondition[i.toString()]["gearBaseName"] = gears[i].productName;
            gearsCondition[i.toString()]["gearVariationName"] = gears[i].variationName;
            gearsCondition[i.toString()]["gearId"] = gears[i].productId;
            gearsCondition[i.toString()]["variationId"] = gears[i].productVariationId;
            gearsCondition[i.toString()]["gearAssignedOn"] = gears[i].assignedAt;
            gearsCondition[i.toString()]["eligibleForExchange"] = gears[i].exchangeAvailableAt;


            setGearsCondition({...gearsCondition});
        }
        //eslint-disable-next-line
    }, [gears]);

    useEffect(() => {
        for (let i = 0; i < gearsNotTalabat.length; i++) {
            gearsNotTalabatCondition[i.toString()] = {};

            gearsNotTalabatCondition[i.toString()]["itemStatus"] = undefined;
            gearsNotTalabatCondition[i.toString()]["imageUrl"] = gearsNotTalabat[i].imageUrl;
            gearsNotTalabatCondition[i.toString()]["gearName"] = gearsNotTalabat[i].name;
            gearsNotTalabatCondition[i.toString()]["gearId"] = gearsNotTalabat[i].id;
            gearsNotTalabatCondition[i.toString()]["variationId"] = undefined;
            gearsNotTalabatCondition[i.toString()]["gearAssignedOn"] = undefined;
            gearsNotTalabatCondition[i.toString()]["eligibleForExchange"] = undefined;


            setGearsNotTalabatCondition({...gearsNotTalabatCondition});
        }
        //eslint-disable-next-line
    }, [gearsNotTalabat]);

    function setGearCondition(index: number, status: string|undefined) {
        console.log(gearsCondition[index.toString()]["itemStatus"], index, status);

        gearsCondition[index.toString()]["itemStatus"] = status;
        setGearsCondition({...gearsCondition});
    }

    function setGearNotTalabatCondition(index: number, status: string|undefined) {
        gearsNotTalabatCondition[index.toString()]["itemStatus"] = status;
        setGearsNotTalabatCondition({...gearsNotTalabatCondition});
    }

    const setAllGearsToGoodOrBad = (toGood: boolean) => {
        if (toGood){
            for (let i = 0; i < Object.keys(gearsNotTalabatCondition).length; i++){
                gearsNotTalabatCondition[i.toString()]["itemStatus"] = 'Good';
            }
            for (let i = 0; i < Object.keys(gearsCondition).length; i++){
                gearsCondition[i.toString()]["itemStatus"] = 'Good';
            }
            setGearsCondition({...gearsCondition});
            setGearsNotTalabatCondition({...gearsNotTalabatCondition});
            console.log(gearsCondition)

        }else{
            for (let i = 0; i < Object.keys(gearsNotTalabatCondition).length; i++){
                gearsNotTalabatCondition[i.toString()]["itemStatus"] = undefined;
            }
            for (let i = 0; i < Object.keys(gearsCondition).length; i++){
                gearsCondition[i.toString()]["itemStatus"] = undefined;
            }
            setGearsCondition({...gearsCondition});
            setGearsNotTalabatCondition({...gearsNotTalabatCondition});
        }

        //eslint-disable-next-line
    };

    const handleNext = () => {
        if (gearsCondition === undefined || gearsOverdueCommunicated === undefined || gearsNotTalabatCondition === undefined) {

            return;
        }

        if (!riderHasNoUniform && historicalGear === "1" && Object.keys(gearsCondition).length === 0) {
            setShowError(true);
            return;
        }

        const shitToSend = [];
        for (const key in gearsCondition) {
            if (gearsCondition[key]["itemStatus"] === 'Good') {
                continue;
            }
            shitToSend.push(gearsCondition[key]);
        }

        for (const key in gearsNotTalabatCondition) {
            if (gearsNotTalabatCondition[key]["itemStatus"] === 'Good') {
                continue;
            }
            shitToSend.push(gearsNotTalabatCondition[key]);
        }

        if (shitToSend.length > 0 && gearsConfirmationImage.length === 0){
            setShowErrorMessages(true);
            setTimeout(() => {
                setShowErrorMessages(false);
            }, 5000)
            return;
        }

        const fullData = {
            'meta': {
                'riderApperanceIsGood': riderApperanceIsGood,
                'gearsOverdueCommunicated': gearsOverdueCommunicated,
                'riderHasNoUniform': riderHasNoUniform,
            },
            'data': shitToSend,
            'picture': gearsConfirmationImage[0] ?? null,
        };


        startLoading('vehicle-verification');
        patrollerService.saveGearCheckData(rider.riderId, fullData)?.then(() => {
            setStep(3);
        }).finally(() => {
            finnishLoading('vehicle-verification');
        });


    }
    interface ModalProps {
        message: string;
        onClose: () => void;
    }

    const Modal: React.FC<ModalProps> = ({ message, onClose }) => {
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <p>{message}</p>
                    <button onClick={onClose} className="modal-close-button">
                        Close
                    </button>
                </div>
            </div>
        );
    };

    const handleNoUniformClick = () => {
        setRiderHasNoUniform(true);
        setShowError(false);
    };


    useEffect(() => {
        fetchGears();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            {showAssignNewGearItem ? (
                <AssignNewGearItem
                    setAssignNewGearItem={setShowAssignNewGearItem}
                    fetchGears={fetchGears}
                    riderGears={gears}
                />
            ) : (
                <>
                    <div className="button-container w-80">
                        {historicalGear === "1" && (
                            <button
                                type="button"
                                className="button button--green"
                                onClick={() => setShowAssignNewGearItem(true)}
                            >
                                {t("Add historical gear data")}
                            </button>
                        )}
                        {historicalGear === "1" && Object.keys(gearsCondition).length === 0 && (
                            <button
                                type="button"
                                className={`button ${riderHasNoUniform ? "button--red-selected" : "button--red"}`}
                                onClick={handleNoUniformClick}
                            >
                                {riderHasNoUniform ? t("Uniform not provided") : t("Rider has no uniform")}
                            </button>
                        )}
                    </div>
                    <div style={{height: '30px'}}>&nbsp;</div>
                    <div className="flex flex-col justify-center items-center w-80">
                        {
                            historicalGear === "1" && Object.keys(gearsCondition).length > 0 ? (
                                <label className="checkbox mb-4">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setAllGearsToGoodOrBad(e.target.checked)}
                                        required
                                        checked={riderApperanceIsGood === true}
                                    />
                                    <div className="checkbox__success-icon no-border see-through-background"></div>
                                    <div className="checkbox__text text-white">{t("Rider Appearance is Good")}</div>
                                </label>
                            ) : historicalGear === "0" ? (
                                <label className="checkbox mb-4">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setAllGearsToGoodOrBad(e.target.checked)}
                                        required
                                        checked={riderApperanceIsGood === true}
                                    />
                                    <div className="checkbox__success-icon no-border see-through-background"></div>
                                    <div className="checkbox__text text-white">{t("Rider Appearance is Good")}</div>
                                </label>
                            ) : null
                        }
                    </div>
                    <div style={{height: '30px'}}>&nbsp;</div>
                    <div className="text-white text-center mb-4">
                        {t("Gear Provided by Talabat")}
                    </div>
                    <div className="card flex flex-col">
                        <table>
                            <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th className="text-xsmall text-center">{t("Good Condition")}</th>
                                <th className="text-xsmall text-center">{t("Not Wearing")}</th>
                                <th className="text-xsmall text-center">{t("Bad Condition")}</th>
                            </tr>
                            </thead>
                            {
                                Object.keys(gearsCondition).map((index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{width: "36px"}}><img src={gearsCondition[index]["imageUrl"]}
                                                                             alt="gear"/>
                                            </td>
                                            <td>
                                                <div className="text-small">{gearsCondition[index]["gearBaseName"]}
                                                    <span
                                                        className="text-blue">{gearsCondition[index]["gearVariationName"]}</span>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <label className="checkbox mb-4">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => setGearCondition(parseInt(index), e.target.checked ? 'Good' : undefined)}
                                                        required
                                                        checked={gearsCondition[index]["itemStatus"] === 'Good'}
                                                    />
                                                    <div className="checkbox__success-icon no-border bg-gray"></div>
                                                </label>
                                            </td>
                                            <td className="text-center">
                                                <label className="checkbox mb-4">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => setGearCondition(parseInt(index), e.target.checked ? 'NotWearing' : undefined)}
                                                        required
                                                        checked={gearsCondition[index]["itemStatus"] === 'NotWearing'}
                                                    />
                                                    <div className="checkbox__success-icon no-border bg-gray"></div>
                                                </label>
                                            </td>
                                            <td className="text-center">
                                                <label className="checkbox mb-4">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => setGearCondition(parseInt(index), e.target.checked ? 'Bad' : undefined)}
                                                        required
                                                        checked={gearsCondition[index]["itemStatus"] === 'Bad'}
                                                    />
                                                    <div className="checkbox__success-icon no-border bg-gray"></div>
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>

                    </div>
                    <>
                        {
                            ((gears && gears.filter((product, index) => {
                                return !(product.exchangeDays && product.returnStatus !== ReturnStatus.MISSING);

                            }).length > 0) || (missingKitProducts && missingKitProducts.products.length > 0)) && <>
                                <div style={{height: '30px'}}>&nbsp;</div>
                                <div className="text-white text-center mb-4">
                                    {t("Gear Overdue or Not Received")}
                                </div>
                                <div className="card">
                                    <table>
                                        <tbody>
                                        {
                                            gears && gears.map((product, index) => {
                                                if (product.exchangeDays && product.returnStatus !== ReturnStatus.MISSING) {
                                                    return null;
                                                }
                                                return (
                                                    <tr key={index}>
                                                        <td style={{width: "36px"}}><img src={product.productImage} alt="gear"/>
                                                        </td>
                                                        <td>
                                                            <div className="text-small">{product.productName}</div>
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="text-small text-blue">{t('Overdue since:')} {product.exchangeAvailableAtConst}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            missingKitProducts && missingKitProducts.products.map((product, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{width: "36px"}}><img src={product.image} alt="gear"/></td>
                                                        <td>
                                                            <div className="text-small">{product.name}</div>
                                                        </td>
                                                        <td>
                                                            <div className="text-small text-red">{t('Not received')}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    <div style={{height: '15px'}}>&nbsp;</div>
                                    <div className="flex flex-col justify-start items-start w-80">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => setGearsOverdueCommunicated(e.target.checked)}
                                                required
                                                checked={gearsOverdueCommunicated === true}
                                            />
                                            <div className="checkbox__success-icon no-border bg-gray"></div>
                                            <div className="checkbox__text ">{t("Communicated to the rider!")}</div>
                                        </label>
                                    </div>
                                </div>
                            </>
                        }
                    </>


                    <div style={{height: '30px'}}>&nbsp;</div>
                    <div className="text-white text-center mb-4">
                        {t("Gear Not Provided by Talabat")}
                    </div>
                    <div className="card flex flex-col">
                        <table>
                            <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th className="text-xsmall text-center">{t("Good Condition")}</th>
                                <th className="text-xsmall text-center">{t("Not Wearing")}</th>
                                <th className="text-xsmall text-center">{t("Bad Condition")}</th>
                            </tr>
                            </thead>
                            {
                                Object.keys(gearsNotTalabatCondition).map((index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{width: "36px"}}><img
                                                src={gearsNotTalabatCondition[index]["imageUrl"]}
                                                alt="gear"/></td>
                                            <td>
                                                <div
                                                    className="text-small">{gearsNotTalabatCondition[index]["gearName"]}</div>
                                            </td>
                                            <td className="text-center">
                                                <label className="checkbox mb-4">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => setGearNotTalabatCondition(parseInt(index), e.target.checked ? 'Good' : undefined)}
                                                        required
                                                        checked={gearsNotTalabatCondition[index]["itemStatus"] === 'Good'}
                                                    />
                                                    <div className="checkbox__success-icon no-border bg-gray"></div>
                                                </label>
                                            </td>
                                            <td className="text-center">
                                                <label className="checkbox mb-4">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => setGearNotTalabatCondition(parseInt(index), e.target.checked ? 'NotWearing' : undefined)}
                                                        required
                                                        checked={gearsNotTalabatCondition[index]["itemStatus"] === 'NotWearing'}
                                                    />
                                                    <div className="checkbox__success-icon no-border bg-gray"></div>
                                                </label>
                                            </td>
                                            <td className="text-center">
                                                <label className="checkbox mb-4">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => setGearNotTalabatCondition(parseInt(index), e.target.checked ? 'Bad' : undefined)}
                                                        required
                                                        checked={gearsNotTalabatCondition[index]["itemStatus"] === 'Bad'}
                                                    />
                                                    <div className="checkbox__success-icon no-border bg-gray"></div>
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>

                    </div>
                    <div style={{height: '30px'}}>&nbsp;</div>

                </>)}

            {showAssignNewGearItem ? null : (
                <>
                {!riderApperanceIsGood && (
                        <ImageInput
                            images={gearsConfirmationImage}
                            setImages={setGearsConfirmationImage}
                            title={t("Confirmation Picture")}
                            multiple={false}
                            required={false}
                        />
                    )}
                    {showErrorMessages && (
                        <div className="text-red">{t("Please upload an image!")}</div>
                    )}
                    {showError && (
                        <Modal
                            message={t("Rider must have at least one gear, please use the add new historical gear function.")}
                            onClose={() => setShowError(false)}
                        />
                    )}
                    <div className="flex flex-col items-center mt-6">
                        <div>
                            <button
                                type="submit"
                                className="button button--green"
                                onClick={() => handleNext()}
                            >
                                {t("Next")}
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col items-center mt-4">
                        <div>
                            <button type="submit" className="button" onClick={() => setStep(1)}>
                                {t("< Back")}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default GearCheckStep;