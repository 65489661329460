import request, { Method } from "../request";

export interface DashboardInformation {
  checksToday: number | null;
  warningsGiven: number | null;
  fakeRiderIdsFound: number | null;
  wrongVehiclesFound: number | null;
  qualityScore: number | null;
  currentDailyGoal: number | null;
  kpiStatistics?: KpiStatistics | null;
}

export interface KpiStatistics {
  score: number | null;
  auditscore: number | null;
  accuracy: number | null;
  survey: number | null;
  assessment: number | null;
  attendance: number | null;
  total: number | null;
  scoreDate: string | null;
}

export interface DailyGoalRequests {
  id: number | null;
  profiles_id: number | null;
  goal: number | null;
  date: string | null;
  approved: boolean;
  approved_at: number;
  created_at: number;
  updated_at: number;
  approved_by: number | null;
  modification_request: string | null;
  modification_response: string | null;
}

class PatrollerService {

  async getDashboardInformation() {
    return request<DashboardInformation>(`/t-patroller/dashboard`, Method.GET);
  }

  async getDailyGoalRequests() {
    return request<DailyGoalRequests[]>(`/t-patroller/get-daily-goal-requests`, Method.GET);
  }

  async createDailyGoalRequest(date: string, goal: string, note: string) {
    return request<any>(`/t-patroller/create-daily-goal-request`, Method.POST, {
        data: {
            date: date,
            daily_goal: goal,
            note: note,
        },
    });
  }

  async isRiderReadyForCheck(riderId: number|string) {
    return request<any>(`/t-patroller/rider-ready-for-check`, Method.POST, {
        data: { rider_id: riderId },
    });
  }

  async listHistory(riderId: number|string) {
    return request<any>(`/t-patroller/history`, Method.POST, {
      data: { rider_id: riderId },
    });
  }

  async latestCheckDate(riderId: number|string) {
    return request<any>(`/t-patroller/get-latest-check-date`, Method.POST, {
      data: { rider_id: riderId },
    });
  }

  async getCheckForRider(riderId: number|string) {
    let check = null;
    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition(function (position){
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        check = request<any>(`/t-patroller/get-check-for-rider`, Method.POST, {
          data: { rider_id: riderId, latitude: latitude, longitude: longitude },
        });
      }, function (error) {
        check = request<any>(`/t-patroller/get-check-for-rider`, Method.POST, {
          data: { rider_id: riderId, latitude: null, longitude: null },
        });
      });
    }
    else {
      check = request<any>(`/t-patroller/get-check-for-rider`, Method.POST, {
        data: { rider_id: riderId, latitude: null, longitude: null },
      });
    }

    while (check === null){
        await new Promise(r => setTimeout(r, 1000));
    }

    return check;
  }

  setCheckForRider(riderId: number|string, checkId: number|string) {
    localStorage.setItem(`checkId|${riderId}`, checkId.toString());
  }

    getCheckForRiderFromLocalStorage(riderId: number|string) {
        return localStorage.getItem(`checkId|${riderId}`);
    }

    saveIdStepCheckForRider(riderId: number|string, isIdValid: boolean, idPicture: any) {
      let checkId = this.getCheckForRiderFromLocalStorage(riderId);
      if (!checkId){
        return;
      }
      console.log(isIdValid);
        return request<any>(`/t-patroller/check-rider-id`, Method.POST, {
            data: {
              check_id: checkId,
              is_id_valid: isIdValid ? '1' : '0',
              id_picture: idPicture,
            },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    }

  saveVehicleStepCheckForRider(riderId: number|string, isLicensePlateValid: boolean, licensePlateNumber: string|null, licensePlatePicture: any, validLicensePlatePicture: any) {
    let checkId = this.getCheckForRiderFromLocalStorage(riderId);
    if (!checkId){
      return;
    }
    return request<any>(`/t-patroller/check-license-plate`, Method.POST, {
      data: {
        check_id: checkId,
        is_license_plate_valid: isLicensePlateValid ? '1' : '0',
        license_plate_number: licensePlateNumber,
        license_plate_picture: licensePlatePicture,
        correct_license_plate_picture: validLicensePlatePicture,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  saveGearCheckData(riderId: number|string, gearCheckData: any) {
    let checkId = this.getCheckForRiderFromLocalStorage(riderId);
    if (!checkId){
      return;
    }
    const formData = new FormData();
    let dataToSend = {
      check_id: checkId,
      gear_check_data: gearCheckData,
    };
    //eslint-disable-next-line
    for (let key in dataToSend) {
      // @ts-ignore
      if (typeof dataToSend[key] === 'object') {
        // @ts-ignore
        for (let subKey in dataToSend[key]) {
          // @ts-ignore
          if (Array.isArray(dataToSend[key][subKey])) {
            // If the value is an array, append each element separately
            // @ts-ignore
            dataToSend[key][subKey].forEach((item:any, index:any) => {
              formData.append(`${key}[${subKey}][${index}]`, item);
            });
            // @ts-ignore
          } else if(typeof dataToSend[key][subKey] === 'object' && subKey !== 'picture') {
            // @ts-ignore
            for (let subSubKey in dataToSend[key][subKey]) {
              // @ts-ignore
              if (Array.isArray(dataToSend[key][subKey][subSubKey])) {
                // If the value is an array, append each element separately
                // @ts-ignore
                dataToSend[key][subKey][subSubKey].forEach((item:any, index:any) => {
                  formData.append(`${key}[${subKey}][${subSubKey}][${index}]`, item);
                });
                // @ts-ignore
              } else if(typeof dataToSend[key][subKey][subSubKey] === 'object' && subSubKey !== 'picture'){
                //@ts-ignore
                for (let subSubSubKey in dataToSend[key][subKey][subSubKey]) {
                  // @ts-ignore
                  if (Array.isArray(dataToSend[key][subKey][subSubKey][subSubSubKey])) {
                    // If the value is an array, append each element separately
                    // @ts-ignore
                    dataToSend[key][subKey][subSubKey][subSubSubKey].forEach((item:any, index:any) => {
                      formData.append(`${key}[${subKey}][${subSubKey}][${subSubSubKey}][${index}]`, item);
                    });
                    // @ts-ignore
                  }
                  else {
                    // @ts-ignore
                    formData.append(`${key}[${subKey}][${subSubKey}][${subSubSubKey}]`, dataToSend[key][subKey][subSubKey][subSubSubKey]);
                  }
                }
              }
              else {
                // @ts-ignore
                formData.append(`${key}[${subKey}][${subSubKey}]`, dataToSend[key][subKey][subSubKey]);
              }
            }
          }else {
            // @ts-ignore
            formData.append(`${key}[${subKey}]`, dataToSend[key][subKey]);
          }
        }
      } else {
        // @ts-ignore
        formData.append(key, dataToSend[key]);
      }
    }
    return request<any>(`/t-patroller/save-gears-check-data`, Method.POST, {
      data: {
        check_id: checkId,
        gear_check_data: gearCheckData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  saveQuestionerData(riderId: number|string, questionerData: any) {
    let checkId = this.getCheckForRiderFromLocalStorage(riderId);
    if (!checkId){
      return;
    }

      const formData = new FormData();
    let dataToSend = {'check_id': checkId, 'questioner_data': questionerData};
      //eslint-disable-next-line
        for (let key in dataToSend) {
          // @ts-ignore
          if (typeof dataToSend[key] === 'object') {
            // @ts-ignore
            for (let subKey in dataToSend[key]) {
              // @ts-ignore
              if (Array.isArray(dataToSend[key][subKey])) {
                // If the value is an array, append each element separately
                // @ts-ignore
                dataToSend[key][subKey].forEach((item:any, index:any) => {
                  formData.append(`${key}[${subKey}][${index}]`, item);
                });
                // @ts-ignore
              } else if(typeof dataToSend[key][subKey] === 'object' && subKey !== 'imageUrl') {
                // @ts-ignore
                for (let subSubKey in dataToSend[key][subKey]) {
                  // @ts-ignore
                  if (Array.isArray(dataToSend[key][subKey][subSubKey])) {
                    // If the value is an array, append each element separately
                    // @ts-ignore
                    dataToSend[key][subKey][subSubKey].forEach((item:any, index:any) => {
                      formData.append(`${key}[${subKey}][${subSubKey}][${index}]`, item);
                    });
                    // @ts-ignore
                  } else if(typeof dataToSend[key][subKey][subSubKey] === 'object' && subSubKey !== 'imageUrl'){
                    //@ts-ignore
                    for (let subSubSubKey in dataToSend[key][subKey][subSubKey]) {
                      // @ts-ignore
                      if (Array.isArray(dataToSend[key][subKey][subSubKey][subSubSubKey])) {
                        // If the value is an array, append each element separately
                        // @ts-ignore
                        dataToSend[key][subKey][subSubKey][subSubSubKey].forEach((item:any, index:any) => {
                          formData.append(`${key}[${subKey}][${subSubKey}][${subSubSubKey}][${index}]`, item);
                        });
                        // @ts-ignore
                      }
                      else {
                        // @ts-ignore
                        formData.append(`${key}[${subKey}][${subSubKey}][${subSubSubKey}]`, dataToSend[key][subKey][subSubKey][subSubSubKey]);
                      }
                    }
                  }
                  else {
                    // @ts-ignore
                    formData.append(`${key}[${subKey}][${subSubKey}]`, dataToSend[key][subKey][subSubKey]);
                  }
                }
              }else {
                // @ts-ignore
                formData.append(`${key}[${subKey}]`, dataToSend[key][subKey]);
              }
            }
          } else {
            // @ts-ignore
            formData.append(key, dataToSend[key]);
          }
        }

    return request<any>(`/t-patroller/save-questioner-data`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getNonTalabatGears() {
    return request<any>(`/t-patroller/get-non-talabat-products`, Method.GET);
  }

  getQuestionerQuestions() {
    return request<any>(`/t-patroller/get-questioner-questions`, Method.GET);
  }

  finishCheckForRider(riderId: number|string) {
    let checkId = this.getCheckForRiderFromLocalStorage(riderId);
    if (!checkId){
      return;
    }
    return request<any>(`/t-patroller/finish-check`, Method.POST, {
      data: {
        check_id: checkId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async savePatrollerLocation(latitude: number, longitude: number) {
    let currentDateTime = new Date();
    let formattedDateTime = currentDateTime.toISOString().slice(0, 19);
    formattedDateTime = formattedDateTime.replace('T', ' ');
    formattedDateTime = formattedDateTime.replace('Z', '');

    return request<any>(`/t-patroller/save-gps-location`, Method.POST, {
      data: {
        latitude: latitude,
        longitude: longitude,
        timestamp: formattedDateTime
      },
    });
  }
}


export const patrollerService= new PatrollerService();
